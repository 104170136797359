import React from "react"

const Drop = ({
    width = "525px",
    viewBox = "0 0 525 525",
    fill = "#F4F5F8",
    ...rest
}) => {
    return (
        <svg
            width={width}
            viewBox={viewBox}
            {...rest}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M262.138841,1.77635684e-14 L262.515703,1.77635684e-14 C407.293474,1.77635684e-14 524.780164,117.273869 525,262.123116 C525,267.242462 524.528923,272.204774 524.246276,277.229899 C524.02644,281.187186 524.057845,285.238693 523.649578,289.133166 C523.021475,295.44598 521.89089,301.633166 520.823114,307.820352 C520.414847,310.238693 520.195011,312.751256 519.692529,315.138191 C518.185081,322.613065 516.206556,329.930905 514.071006,337.154523 C513.788359,338.190955 513.599928,339.258794 513.285877,340.232412 C480.184842,447.14196 380.630496,524.842965 262.861159,525 L261.259496,525 C116.889992,524.309045 0.0314051564,407.129397 0.0314051564,262.594221 L0,0.376884422 L262.138841,1.77635684e-14 Z"
                    fill={fill}
                />
            </g>
        </svg>
    )
}

export default Drop
