import React from "react"
import withLayout from "../../layout"
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";
import EventDetailTemplate from "../../components/EventDetailTemplate"
import eventsList from "../../utilities/webinars"
import TestimonialImage from "../../images/agency/cristian_belli.jpg"

const SingleEventPageWrapper = ({intl}) => {
    const {
        name,
        link,
        title,
        date,
        times,
        price,
        image,
        imagesrc,
        id,
        canSubscribe
    } = eventsList.find(event => event.link === "guida-come-mintare-un-nft")

    const templateData = {
        canSubscribe,
        name,
        link,
        title,
        testimonial: {
            image: TestimonialImage,
            name: "Cristian Belli",
            role: "Back-End Developer | Kromin"
        },
        date,
        times,
        location: "events.online",
        price,
        image,
        imagesrc,
        id,
        description: "guida_come_mintare_un_nft.description",
        list: [
            "guida_come_mintare_un_nft.list_1",
            "guida_come_mintare_un_nft.list_2",
            "guida_come_mintare_un_nft.list_3",
            "guida_come_mintare_un_nft.list_4"
        ],
        categories: [
            "events.marketing",
            "events.data"
        ],
        intl
    }
    const keywords = intl.formatMessage({id:"events.meta_keywords"}).split(",") || []

    return (
        <>
            <SEO
                title={intl.formatMessage({id: "events.meta_title"})}
                description={intl.formatMessage({id: "events.meta_description"})}
                keywords={keywords}
                lang={intl.locale}
            />

            <EventDetailTemplate
                {...templateData}
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "events", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(SingleEventPageWrapper))
