import styled from "styled-components";
import PropTypes from "prop-types";

const Dash = styled.div`
    width: 50px;
    height: 3px;
    background: ${(props) => props.background ? props.background : 'linear-gradient(-90deg, #b84ff8 0%, #8227ee 100%)'};
    margin: ${(props) => props.margin};
`;

Dash.propTypes = {
    margin: PropTypes.string,
    background: PropTypes.string,
};

export default Dash;
